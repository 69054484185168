import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useNotify } from 'react-admin';
import dataProvider from '../../../../config/dataProvider';

export const ReinitiateMercury = ({ companyId }: { companyId: number }) => {
  const [inputValue, setInputValue] = useState('');
  const [processing, setProcessing] = useState(false);
  const [signupLink, setSignupLink] = useState('');
  const notify = useNotify();

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleButtonClick = async () => {
    if (!validateEmail(inputValue)) {
      notify('Invalid email address', { type: 'warning' });
      return;
    }

    try {
      setProcessing(true);
      const data = {
        email: inputValue,
      };
      dataProvider
        .post(`update/company/${companyId}/mercury/reinitiate`, { data })
        .then(({ data, error }) => {
          if (data?.signupLink) {
            setSignupLink(data.signupLink);
            notify('Mercury initiated successfully', { type: 'success' });
            return;
          }
          if (error) {
            throw new Error(error);
          }
        })
        .catch((error) => {
          notify(error.message || error.error || error.code || error, { type: 'error' });
        })
        .finally(() => setProcessing(false));
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleCopyClick = () => {
    navigator.clipboard.writeText(signupLink);
    notify('Signup link copied to clipboard', { type: 'info' });
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Reinitiate Mercury
          </Typography>
        }
        onClick={handleExpandClick}
        style={{ cursor: 'pointer' }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                label="New Email"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                disabled={processing}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
                disabled={processing}
              >
                {processing ? 'Processing...' : 'Reinitiate Mercury'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                This will remove all other mercury applications for this company from the system,
                and initiate with the email provided.
              </Typography>
            </Grid>
            {signupLink && (
              <Grid item xs={12}>
                <TextField
                  label="Signup Link"
                  value={signupLink}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Tooltip title="Copy to clipboard">
                        <IconButton onClick={handleCopyClick}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};
