import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useNotify } from 'react-admin';
import { Controller, useForm } from 'react-hook-form';
import dataProvider from '../../../../config/dataProvider';

export const ReconcileESignature = ({ companyId }: { companyId: number }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      originalDocumentId: '',
      provider: 'DOCUMENSO',
      purpose: '',
      keyId: '',
    },
  });
  const [processing, setProcessing] = useState(false);
  const notify = useNotify();

  const onSubmit = async (data: any) => {
    console.log({ data });
    try {
      setProcessing(true);
      dataProvider
        .post(`update/company/${companyId}/signature/reconciliation`, { data })
        .then(({ data, error }) => {
          if (data?.value === 'ok') {
            notify('E-Signature reconciled successfully', { type: 'success' });
            return;
          }
          if (error) {
            throw new Error(error);
          }
        })
        .catch((error) => {
          notify(error.message || error.error || error.code || error, { type: 'error' });
        })
        .finally(() => setProcessing(false));
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setProcessing(false);
    }
  };

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Reconcile E-Signature
          </Typography>
        }
        onClick={handleExpandClick}
        style={{ cursor: 'pointer' }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8}>
                <Controller
                  name="originalDocumentId"
                  control={control}
                  rules={{ required: 'Document is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Original Document ID"
                      disabled={processing}
                      fullWidth
                      error={!!errors.originalDocumentId}
                      helperText={
                        errors.originalDocumentId ? errors.originalDocumentId.message : ''
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="keyId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="KeyId"
                      disabled={processing}
                      fullWidth
                      error={!!errors.keyId}
                      helperText={errors.keyId ? errors.keyId.message : ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="provider"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="SIGNEASY"
                        control={<Radio />}
                        disabled
                        label="Signeasy"
                      />
                      <FormControlLabel value="DOCUMENSO" control={<Radio />} label="Documenso" />
                    </RadioGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Purpose</InputLabel>
                  <Controller
                    name="purpose"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} label="Purpose">
                        <MenuItem disabled>SS4 for EIN application</MenuItem>
                        <MenuItem value="SS4">SS4</MenuItem>
                        <MenuItem value="SS4_WITH_ACKNOWLEDGEMENT">
                          SS4 with Acknowledgement
                        </MenuItem>
                        <MenuItem value="SS4_WITH_8821">SS4 with 8821</MenuItem>
                        <MenuItem disabled>Federal Tax</MenuItem>
                        <MenuItem value="FEDERAL_TAX_1120_5472">Federal Tax 1120/5472</MenuItem>
                        <MenuItem value="FEDERAL_TAX_STRATEGCUBE_1120_5472">
                          Federal Tax Strategcube 1120/5472
                        </MenuItem>
                        <MenuItem value="FEDERAL_TAX_RESOLUTION_1120_5472">
                          Federal Tax Resolution 1120/5472
                        </MenuItem>
                        <MenuItem value="FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION">
                          Federal Tax 1065 and E-File Authorization
                        </MenuItem>
                        <MenuItem value="FEDERAL_TAX_STRATEGCUBE_1065_AND_EFILE_AUTHORIZATION">
                          Federal Tax Strategcube 1065 and E-File Authorization
                        </MenuItem>
                        <MenuItem value="FEDERAL_TAX_STRATEGCUBE_1120_AND_EFILE_AUTHORIZATION">
                          Federal Tax Strategcube 1120 and E-File Authorization
                        </MenuItem>
                        <MenuItem value="FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION">
                          Federal Tax 1120 and E-File Authorization
                        </MenuItem>
                        <MenuItem value="FEDERAL_TAX_POA_2848">Federal Tax POA 2848</MenuItem>
                        <MenuItem value="FEDERAL_TAX_MEMBER_RESOLUTION">
                          Federal Tax Member Resolution
                        </MenuItem>
                        <MenuItem value="TAX_OFFICER_RESOLUTION">Tax Officer Resolution</MenuItem>

                        <MenuItem disabled>EIN</MenuItem>
                        <MenuItem value="EIN_8822B_FOR_RESPONSIBLE_PARTY_CHANGE">
                          EIN 8822B for Responsible Party Change
                        </MenuItem>
                        <MenuItem value="EIN_CANCELLATION">EIN Cancellation</MenuItem>
                        <MenuItem value="EIN_NAME_CORRECTION_2848">
                          EIN Name Correction 2848
                        </MenuItem>

                        <MenuItem disabled>Company</MenuItem>
                        <MenuItem value="COMPANY_CLOSURE">Company Closure</MenuItem>
                        <MenuItem value="REGISTERED_AGENT_TRANSFER">
                          Registered Agent Transfer
                        </MenuItem>
                        <MenuItem value="CERTIFICATE_OF_FORMATION">
                          Certificate of Formation
                        </MenuItem>
                        <MenuItem value="FORM_8821">Form 8821</MenuItem>
                        <MenuItem value="CONSENT_AND_INDEMNIFICATION_AGREEMENT_FOR_FORMATION_FILING">
                          Consent and Indemnification Agreement for Formation Filing
                        </MenuItem>
                        <MenuItem value="ARTICLE_OF_ORGANIZATION_AMENDMENT">
                          Article of Organization Amendment
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit" disabled={processing}>
                  {processing ? 'Processing...' : 'Reconcile E-Signature'}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="error">
                  This will reconcile the e-signature for this company with the provided URL.
                </Typography>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Collapse>
    </Card>
  );
};
